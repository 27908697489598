import React from "react";
import Layout from "../components/layout";

export default function Blog() {
    return (
        <Layout>
            <div className="container">
                <h1 style={{ paddingTop: `2rem`}}>Coming soon...</h1>
            </div>  
        </Layout>
    );
}